const bodyWrap = document.querySelector("body");
const menuToggle = document.querySelector(".site-menu-toggle");
const closeMenu = document.querySelector(".close-menu");
const hamburger = document.querySelector(".hamburger");
const menuMobile = document.querySelector(".mobile-nav-wrap");
const searchToggle = document.querySelector(".search-toggle");
const searchWrap = document.querySelector(".search-overlay");
const backTop = document.querySelector(".back-to-top");
const counterItem = document.querySelectorAll(".counter");
const setBackground = document.querySelectorAll("[setBackground]");
const sideNav = document.querySelector(".sidenav-group");

document.addEventListener("DOMContentLoaded", () => {
	mappingFunc();
	swiperInit();
	generalFunction.init();
});

window.addEventListener("resize", function () {
	let width = this.window.innerWidth;
	if (width <= 1200.1) {
		mappingFunc();
		hideElement();
	}
});
window.addEventListener("scroll", function () {
	const width = this.window.innerWidth;

	const scrollTop = window.scrollY || document.documentElement.scrollTop;

	var scrollDistance = $(window).scrollTop();
	// Assign active class to nav links while scolling
	$(".about-section-wrap .about-section-id").each(function (i) {
		if (
			$(this).position().top -
				$(".sticky-nav").outerHeight() -
				$("header").outerHeight() -
				1 <=
			scrollDistance
		) {
			$(".sticky-nav li.active").removeClass("active");
			$(".sticky-nav li").eq(i).addClass("active");
		}
	});
	if (width >= 1200.1) {
		if (
			document.body.scrollTop > 100 ||
			document.documentElement.scrollTop > 100
		) {
			bodyWrap.classList.add("minimize");
		} else {
			bodyWrap.classList.remove("minimize");
		}
	}

	if (scrollTop > window.innerHeight) {
		sideNav.classList.add("show");
	} else {
		sideNav.classList.remove("show");
	}
});
document.addEventListener("click", (event) => {
	if (!event.target.closest(".search-toggle, .search-overlay")) {
		searchWrap.classList.remove("is-open");
	}
	if (!event.target.closest(".mobile-nav-wrap, .site-menu-toggle")) {
		menuMobile.classList.remove("is-open");
		bodyWrap.classList.remove("overlay-bg");
		hamburger.classList.remove("is-active");
	}
});

Fancybox.bind("[data-fancybox]", {
	parentEl: document.body[0], // Element containing main structure
});

const generalFunction = {
	toggleFunc: () => {
		const width = this.window.innerWidth;
		menuToggle.addEventListener("click", () => {
			menuMobile.classList.toggle("is-open");
			hamburger.classList.toggle("is-active");
			bodyWrap.classList.toggle("overlay-bg");
		});
		searchToggle.addEventListener("click", () => {
			searchWrap.classList.toggle("is-open");
			bodyWrap.classList.toggle("overlay-bg");
		});
		closeMenu.addEventListener("click", () => {
			menuMobile.classList.remove("is-open");
			hamburger.classList.remove("is-active");
			bodyWrap.classList.remove("overlay-bg");
		});
		//Mobile Side Nav
		$(".side-mobile-nav .title-nav").on("click", function () {
			$(".side-nav").slideToggle();
		});
		//Toggle Nav
		$(".drop-menu .title em").on("click", function () {
			if ($(this).parents(".drop-menu").hasClass("active")) {
				$(".drop-menu .nav-menu").slideUp();
				$(".drop-menu").removeClass("active");
			} else {
				$(".drop-menu .nav-menu").slideUp();
				$(".drop-menu").removeClass("active");
				$(this).parent().next().slideDown();
				$(this).parents(".drop-menu").addClass("active");
			}
		});
		$(".mobile-nav-menu .title em").on("click", function () {
			$(this).parent().next().slideToggle();
		});
		if (width >= 1200.1) {
			$(".mega-nav .mega-link .title-wrap a").on(
				"mouseenter",
				function () {
					if ($(this).parents(".mega-link").hasClass("is-toggle")) {
						$(".mega-nav .mega-link").removeClass("is-toggle");
					} else {
						var display = $(this).attr("data-type");
						$(".mega-panel-wrap .mega-panel").removeClass(
							"active-panel"
						);
						$("#" + display).addClass("active-panel");
						$(this)
							.parents(".mega-nav")
							.find(".mega-link")
							.removeClass("is-toggle");
						$(this).parents(".mega-link").addClass("is-toggle");
					}
				}
			);
		}

		// getting HTML elements
		const nav = document.querySelector(".sidenav-group"),
			toggleBtn = nav.querySelector(".sidenav-group .toggle-btn");

		toggleBtn.addEventListener("click", () => {
			nav.classList.toggle("open");
		});

		// js code to make draggable nav
		function onDrag({ movementY }) {
			//movementY gets mouse vertical value
			const navStyle = window.getComputedStyle(nav), //getting all css style of nav
				navTop = parseInt(navStyle.top), // getting nav top value & convert it into string
				navHeight = parseInt(navStyle.height), // getting nav height value & convert it into string
				windHeight = window.innerHeight; // getting window height

			nav.style.top = navTop > 0 ? `${navTop + movementY}px` : "1px";
			if (navTop > windHeight - navHeight) {
				nav.style.top = `${windHeight - navHeight}px`;
			}
		}

		//this function will call when user click mouse's button and  move mouse on nav
		nav.addEventListener("mousedown", () => {
			nav.addEventListener("mousemove", onDrag);
		});

		//these function will call when user relase mouse button and leave mouse from nav
		nav.addEventListener("mouseup", () => {
			nav.removeEventListener("mousemove", onDrag);
		});
		nav.addEventListener("mouseleave", () => {
			nav.removeEventListener("mousemove", onDrag);
		});
	},
	backTop: () => {
		backTop.addEventListener("click", (event) => {
			event.preventDefault();
			$("html, body").animate({ scrollTop: 0 }, "300");
		});
	},
	tabFunction: () => {
		$(".tab-nav a").on("click", function () {
			$(this).parents(".tab-nav").find("li").removeClass("active");
			$(this).parents("li").addClass("active");

			var tab = $(this).attr("data-type");
			$(this).parents("section").find(".tab-item").removeClass("active");
			$(this)
				.parents("section")
				.find("#" + tab)
				.addClass("active");
		});
	},
	dropdownMenu: () => {
		if ($(window).width() < 1200.1) {
			$(".drop-down .title em").on("click", function () {
				if ($(this).parents(".drop-down").hasClass("is-open")) {
					$(".drop-down .nav-sub").slideUp();
					$(".drop-down").removeClass("is-open");
				} else {
					$(".drop-down .nav-sub").slideUp();
					$(".drop-down").removeClass("is-open");
					$(this).parent().next().slideDown();
					$(this).parents(".drop-down").addClass("is-open");
				}
			});
			$(".tertiary-menu-toggle").on("click", function () {
				$(".nav-tertiary-menu").slideToggle();
			});
		}
	},

	counterAnimate: () => {
		if (counterItem !== null) {
			if (counterItem.length) {
				const counterUp = window.counterUp.default;
				const callback = (entries) => {
					entries.forEach((entry) => {
						const el = entry.target;
						if (
							entry.isIntersecting &&
							!el.classList.contains("is-visible")
						) {
							counterUp(el, {
								duration: 2000,
								delay: 200,
							});
							el.classList.add("is-visible");
						}
					});
				};
				const IO = new IntersectionObserver(callback, { threshold: 1 });

				counterItem.forEach((counter) => {
					IO.observe(counter);
				});
			}
		}
	},

	paginationJs: () => {
		$(".news-pagination-wrap").each(function (index) {
			let object = $(this).find(".zone-item").length;
			if (object > 9) {
				var items = $(this).find(".zone-item");
				var numItems = items.length;
				var perPage = 9;
				items.slice(perPage).hide();
				$(this)
					.find(".pagination-wrap")
					.attr("id", "pagination-container-" + index);
				$("#pagination-container-" + index).pagination({
					items: numItems,
					itemsOnPage: perPage,
					displayedPages: 3,
					onPageClick: function (pageNumber) {
						var showFrom = perPage * (pageNumber - 1);
						var showTo = showFrom + perPage;
						items.hide().slice(showFrom, showTo).show();
					},
				});
			}
		});
	},

	setBackground: () => {
		setBackground.forEach((attributes) => {
			attributes.style.cssText = `
			background-image: url(${attributes.getAttribute("setBackground")});
			background-size: cover;
			background-position: center;
		  `;
		});
	},
	toggleItem: () => {
		$(".toggle-wrap").each(function (index, element) {
			let $this = $(this);
			$this.addClass("toggle-instance-" + index);
			$(".toggle-instance-" + index + " .toggle-item").click(function () {
				if ($(this).hasClass("is-toggle")) {
					$(this).find(".article").slideUp();
					$(this).removeClass("is-toggle");
				} else {
					$(this).find(".article").slideDown();
					$(this).addClass("is-toggle");
				}
			});
		});
	},

	a11yUser: () => {
		const wrapForms = document.querySelectorAll(".wrap-form");
		wrapForms.forEach(function (form) {
			const submitInput = form.querySelector("input[type='submit']");
			const labelFor = submitInput.getAttribute("name");
			const labelSubmit = form.querySelector(".frm-btnwrap .label");
			if (labelSubmit !== null) {
				labelSubmit.textContent = "Submit Button";
				labelSubmit.setAttribute("for", labelFor);
			}
		});
	},
	hideElement: () => {
		if ($(window).width() < 1200.1) {
			const [search, hotline] = [".search-wrap", ".header-hotline"].map(
				(selector) => document.querySelector(selector)
			);

			[search, hotline].forEach((el) => (el.style.display = "flex"));
		}
	},
	expandContent: () => {
		$(".read-more-wrap").each(function (index) {
			$(this).addClass("read-instance-" + index);
			let $this = $(".read-instance-" + index);
			$this.each(function () {
				let height = $this.find(".article").outerHeight();
				console.log(height);
				if (height < 300) {
					$this.find(".btn-read-more").addClass("hide");
					console.log("hide");
				} else {
					$this.find(".article").css({
						height: "300",
					});
					console.log("limit");
				}
				let button = $this.find(".btn-read-more");
				let content = $this;
				button.on("click", function () {
					content.toggleClass("active");
				});
			});
		});
	},
	tocInit: () => {
		const headings = document.querySelectorAll(
			".js-toc-content h2,.js-toc-content h3,.js-toc-content h4,.js-toc-content h5,.js-toc-content h6"
		);
		let index = 1;
		headings.forEach((heading) => {
			const id = `${heading.tagName.toLowerCase()}-${index}`;
			heading.id = id;
			index++;
		});
		tocbot.init({
			tocSelector: "#js-toc",
			contentSelector: ".js-toc-content",
			headingSelector: " h2,h3,h4",
			headingsOffset: 80,
			scrollSmooth: true,
			scrollSmoothDuration: 620,

			collapseDepth: 1,
			scrollSmoothOffset: -80,
		});
	},
	stickyNav: () => {
		$(".sticky-nav a").on("click", function (event) {
			if (this.hash !== "") {
				let offset =
					$("header").outerHeight() + $(".sticky-nav").outerHeight();
				var hash = this.hash;
				$("html, body").animate(
					{
						scrollTop: $(hash).offset().top - offset,
					},
					800,
					function () {
						// window.location.hash = hash;
					}
				);
			} // End if
		});
	},
	appendHTML: () => {
		if ($(".other-service-wrap").length) {
			if ($(".other-service").length) {
				$(".other-service").appendTo(".other-service-wrap");
			}
		}
		if ($("body").hasClass("product-detail-page")) {
			if ($(".product-detail-2 table").length) {
				$(".product-detail-2 table").each(function (index) {
					$(this).wrap("<div class='table-wrap'></div>");
				});
			}
		}
	},
	init: () => {
		generalFunction.toggleFunc();
		generalFunction.backTop();
		generalFunction.tabFunction();
		generalFunction.dropdownMenu();
		generalFunction.counterAnimate();
		generalFunction.setBackground();
		generalFunction.toggleItem();
		generalFunction.hideElement();
		generalFunction.a11yUser();
		generalFunction.expandContent();
		generalFunction.stickyNav();
		generalFunction.tocInit();
		generalFunction.appendHTML();
		generalFunction.paginationJs();
	},
};

function swiperInit() {
	var primarySwiper = new Swiper(".primary-banner .swiper", {
		// Optional parameters
		slidesPerView: 1,
		observer: true,
		observeParents: true,
		preventInteractionOnTransition: true,
		speed: 1205,
		autoplay: false,
		lazy: {
			loadPrevNext: true,
		},
		loop: true,
		navigation: {
			nextEl: ".primary-banner .next",
			prevEl: ".primary-banner .prev",
		},
		pagination: {
			el: ".primary-banner  .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
	});
	var productThumbs = new Swiper(".product-thumb  .swiper", {
		spaceBetween: 10,
		breakpointsInverse: true,
		breakpoints: {
			320: {
				slidesPerView: 3,
			},
			576: {
				slidesPerView: 4,
			},
			768: {
				slidesPerView: 5,
			},
			1024: {
				slidesPerView: 4,
			},
			1280: {
				slidesPerView: 5,
			},
		},
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
	});
	var productMain = new Swiper(".product-main .swiper", {
		slidesPerView: 1,
		centeredSlides: true,
		lazy: {
			loadPrevNext: true,
		},
		autoplay: {
			delay: 3000,
		},
		speed: 750,
		loop: false,
		navigation: {
			nextEl: ".product-main .next-nav",
			prevEl: ".product-main .prev-nav",
		},
		thumbs: {
			swiper: productThumbs,
		},
	});

	$(".init-swiper .swiper").each(function (index) {
		var $this = $(this);
		$this.addClass("swiper-init-" + index);
		$this
			.parent()
			.find(".prev")
			.addClass("prev-nav-" + index);
		$this
			.parent()
			.find(".next")
			.addClass("next-nav-" + index);
		$this
			.parent()
			.find(".swiper-pagination")
			.addClass("pagination-inst-" + index);

		var swiper = new Swiper(".swiper-init-" + index, {
			loop: false,
			speed: 1000,
			autoplay: {
				delay: 4000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			observer: true,
			observeParents: true,
			slidesPerView: "auto",
			preventInteractionOnTransition: true,
			navigation: {
				nextEl: ".next-nav-" + index,
				prevEl: ".prev-nav-" + index,
			},
			pagination: {
				el: ".pagination-inst-" + index,
				type: "bullets",
				clickable: true,
				dynamicBullets: true,
				dynamicMainBullets: 1,
			},
		});
	});
	var historyThumb = new Swiper(".swiper-history-thumb  .swiper", {
		spaceBetween: 5,
		breakpointsInverse: true,
		breakpoints: {
			320: {
				slidesPerView: 2,
			},
			576: {
				slidesPerView: 3,
			},
			768: {
				slidesPerView: 5,
			},
			1024: {
				slidesPerView: 6,
			},
			1200: {
				slidesPerView: 7,
			},
		},
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
	});
	var historyMain = new Swiper(".swiper-history-main .swiper", {
		slidesPerView: 1,
		centeredSlides: true,
		lazy: {
			loadPrevNext: true,
		},
		autoplay: {
			delay: 3000,
		},
		preventInteractionOnTransition: true,
		speed: 750,
		loop: false,
		thumbs: {
			swiper: historyThumb,
		},
		navigation: {
			nextEl: ".swiper-history-main .next",
			prevEl: ".swiper-history-main .prev",
		},
	});
}
function hideElement() {
	if ($(window).width() < 1200.1) {
		const [search, hotline] = [".search-wrap", ".header-hotline"].map(
			(selector) => document.querySelector(selector)
		);

		[search, hotline].forEach((el) => (el.style.display = "flex"));
	}
}

function mappingFunc() {
	new MappingListener({
		selector: ".search-wrap",
		mobileWrapper: ".mobile-top-nav",
		mobileMethod: "insertAfter",
		desktopWrapper: ".language-wrap",
		desktopMethod: "insertAfter",
		breakpoint: 1200.1,
	}).watch();
	new MappingListener({
		selector: ".header-hotline",
		mobileWrapper: ".mobile-nav-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: ".site-menu-toggle",
		desktopMethod: "insertBefore",
		breakpoint: 1200.1,
	}).watch();
}
